<template>
  <div class="p-grid">
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_object_name') }}*</p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <p v-if="!getIsEditable"><strong>{{ getCalendar.name ?? '...' }}</strong></p>
      <div v-else class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="pi pi pi-pencil"></i>
                      </span>
        <InputText v-model="getCalendar.name" :class="!validation.name ? 'p-md-12' : 'p-invalid'"
                   :disabled="!getIsEditable" type="text"/>
      </div>
      <small v-if="validation.name" class="p-error">{{ _t('label.name_required') }}</small>
    </div>

    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label.Description') }}</p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <p v-if="!getIsEditable"><strong>{{ getCalendar.description ?? '...' }}</strong></p>
      <div v-else class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="pi pi pi-pencil"></i>
                      </span>
        <InputText v-model="getCalendar.description" :disabled="!getIsEditable" class="p-md-12" type="text"/>
      </div>
    </div>

    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('ORGANIZATION') }}</p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <p v-if="!getIsEditable"><strong>{{ getCalendar.orgName ?? '...' }}</strong></p>
      <div v-else class="p-inputgroup">
        <OrganizationLink v-model="getCalendar" :editMode="true" :required-field="true"/>
      </div>
    </div>

    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_valid_from') }}</p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <p v-if="!getIsEditable"><strong>{{ moment(getCalendar.validFrom).format('DD.MM.YYYY') }}</strong></p>
      <div v-else class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="pi pi pi-calendar"></i>
                      </span>
        <Calendar id="inputdate" v-model="validFrom" :disabled="!getIsEditable" class="p-md-12 p-p-0"
                  dateFormat="dd.mm.yy" style="min-width: 320px;"/>
      </div>
    </div>

    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_period_replacement') }}</p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <p><strong>{{ _t(weekdays[moment(getCalendar.validFrom).format('d')]) }}
        {{ moment(getCalendar.validFrom).format('HH:mm') }}</strong></p>
    </div>
  </div>

  <div class="sc-wrapper">
    <div class="p-d-flex p-flex-column">
      <div v-if="getIsEditable" class="sc-calendar p-flex p-card p-p-4">
        <div class="p-grid">
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_monday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_tuesday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_wednesday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_thursday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_friday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_saturday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_sunday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 5%">{{ _t('label_holiday_short') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 10%">{{ _t('label_from') }}</div>
          <div class="p-col-fixed sc-calendar__header" style="width: 10%">{{ _t('label_to') }}</div>
          <div class="p-col sc-calendar__header">{{ _t('label_alarm_plan') }}</div>
          <div v-if="getIsEditable" class="p-col-fixed sc-calendar__header" style="width: 5%"></div>
        </div>

        <template v-for="(weekGroup, weekGroupIndex) in getEvents" :key="weekGroupIndex">
          <div class="p-grid">
            <div class="p-col-6 p-text-left">
              <div class="p-py-2 p-pr-4 p-d-inline-block">{{ this._t('label_week') }} {{ weekGroupIndex + 1 }}</div>
              <Button v-if="getIsEditable" class="p-button-text p-button-rounded p-mr-2 p-button-sm"
                      icon="pi pi-plus p-button-icon"
                      @click="createEvent(weekGroupIndex); validateWeeks();"/>
              <Button v-if="getIsEditable" class="p-button-danger p-button-text p-button-rounded p-mr-2 p-button-sm"
                      icon="pi pi-trash"
                      @click="deleteWeek(weekGroupIndex); validateWeeks();"/>
            </div>
          </div>

          <div
              v-for="(event, eventIndex) in weekGroup.alarmPointRotationEvents"
              :key="eventIndex"
              :class="{ invalid: invalidEvents[weekGroupIndex] && invalidEvents[weekGroupIndex].has(eventIndex) }"
              class="p-grid p-nogutter p-py-2"
          >
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.markedDays" :disabled="!getIsEditable" :value="weekDay.MONDAY"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.markedDays" :disabled="!getIsEditable" :value="weekDay.TUESDAY"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.markedDays" :disabled="!getIsEditable" :value="weekDay.WEDNESDAY"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.markedDays" :disabled="!getIsEditable" :value="weekDay.THURSDAY"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.markedDays" :disabled="!getIsEditable" :value="weekDay.FRIDAY"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.markedDays" :disabled="!getIsEditable" :value="weekDay.SATURDAY"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.markedDays" :disabled="!getIsEditable" :value="weekDay.SUNDAY"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 5%">
              <Checkbox v-model="event.dateRule.holiday" :binary="true" :disabled="!getIsEditable"
                        @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 10%">
              <InputMask v-model="event.dateRule.start" :disabled="!getIsEditable" mask="99:99"
                         @change="validateWeeks"/>
            </div>
            <div class="p-col-fixed" style="width: 10%">
              <InputMask v-model="event.dateRule.end" :disabled="!getIsEditable" mask="99:99" @change="validateWeeks"/>
            </div>
            <div class="p-col">
              <Dropdown
                  v-model="event.alarmplanId"
                  :disabled="!getIsEditable"
                  :options="modifiedAlarmPlans"
                  :placeholder="_t('postpone_action')"
                  :showClear="true"
                  optionLabel="name"
                  optionValue="id"
                  style="width: 100%"
              />
            </div>
            <div v-if="getIsEditable" class="p-col-fixed">
              <Button v-if="getIsEditable" class="p-button-danger p-button-text p-mr-2 p-button-rounded"
                      icon="pi pi-trash"
                      @click="removeSelectedEvent(weekGroupIndex, eventIndex); validateWeeks()"/>
              <Button v-if="getIsEditable" class="p-button-text p-button-rounded"
                      icon="pi pi-clone"
                      @click="cloneEvent({ weekGroupIndex, eventIndex }); validateWeeks()"/>
            </div>
          </div>
        </template>

        <div class="p-grid">
          <div class="p-col-12 p-text-left">
            <Button v-if="getIsEditable" :label="_t('label_add_new')" class="p-button-text p-mr-2 p-button-sm"
                    icon="pi pi-plus" @click="addWeek(); validateWeeks()"/>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import {WeekDayEnum} from '@/enums/week-day.enum.js';
import {ViewEnum} from '@/components/CustomFullCalendar/enums/view.enum';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import {mapActions, mapGetters, mapMutations} from "vuex";
import OrganizationLink from "../../components/ixarma/OrganizationLink";

export default {
  components: {OrganizationLink},
  name: 'rotational-calendar-configurator',
  props: {
    orgId: {
      type: String,
      default: "1",
      required: true,
    },
  },
  data: () => ({
    date: moment(),
    events: [],
    firstRun: true,
    calendarView: ViewEnum.TIMELINE,
    calendarType: ViewTypeEnum.MONTH,
    calendarViewTypes: Object.values(ViewTypeEnum).filter(i => i !== ViewTypeEnum.YEAR).map(i => ({
      name: i.toLowerCase(),
      code: i
    })),
    invalidEvents: [],
    renderCalendar: true,
    validation: {
      name: false
    },
    moment,
    weekdays: [
      'label_sunday_short',
      'label_monday_short',
      'label_tuesday_short',
      'label_wednesday_short',
      'label_thursday_short',
      'label_friday_short',
      'label_saturday_short',
    ],
    modifiedAlarmPlans: [],
    weeksCount: 0
  }),
  created() {
    this.moment = moment;
    this.moment.updateLocale('en', {
      week: {
        dow: 1,
      }
    });
  },
  async mounted() {
    await this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()});
    await this.callAlarmPlans(this.orgId);
    await this.callEvents();
  },
  methods: {
    ...mapActions({
      'callEvents': 'rotationalCalendar/callEvents',
      'callAlarmPlans': 'rotationalCalendar/callAlarmPlans',
      'callCreateCalendar': 'rotationalCalendar/callCreateCalendar',
      'callUpdateCalendar': 'rotationalCalendar/callUpdateCalendar',
      'callRemoveCalendar': 'rotationalCalendar/callRemoveCalendar',
      'callGetHolidays': 'holidays/callGetHolidays',
    }),
    ...mapMutations({
      'setCalendar': 'rotationalCalendar/setCalendar',
      'setIsEditable': 'rotationalCalendar/setIsEditable',
      'createWeek': 'rotationalCalendar/createWeek',
      'removeWeek': 'rotationalCalendar/removeWeek',
      'createEvent': 'rotationalCalendar/createEvent',
      'removeEvent': 'rotationalCalendar/removeEvent',
      'cloneEvent': 'rotationalCalendar/cloneEvent',
    }),
    async addWeek() {
      await this.createWeek(this.weeksCount);
      this.weeksCount = this.weeksCount + 1;
    },
    async deleteWeek(weekGroupIndex) {
      await this.removeWeek(weekGroupIndex)
      if (this.weeksCount > 0) {
        this.weeksCount = this.weeksCount - 1;
      }
    },
    removeSelectedCalendar() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.callRemoveCalendar();
          this.validateWeeks();
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    removeSelectedEvent(weekGroupIndex, eventIndex) {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.removeEvent({weekGroupIndex, eventIndex});
          this.validateWeeks();
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    generateEvents: function () {
      if (!this.getEvents) return [];
      let events = [];
      const eventGroups = JSON.parse(JSON.stringify(this.getEvents));
      const countWeeks = eventGroups.length;

      let currentWeek = 0;
      eventGroups.map((week) => {
        week.alarmPointRotationEvents.map(eventRule => {
          let times = [];
          let currentWeekInMonth = 0;
          const calendar = this.getCalendars.find(i => i.id === eventRule.rotationalCalendarId) ?? this.getCalendar;
          const alarmPlan = this.getAlarmPlans.find(i => i.id === eventRule.alarmplanId) ?? null;
          for (
              let d = moment(this.date).startOf('months').startOf('weeks').hours(12);
              d.diff(moment(this.date).endOf('months').endOf('weeks'), 'days') <= 0;
              d.add(1, 'days')
          ) {
            const holiday = this.getHolidays ? this.getHolidays.find(i =>
                moment(i.start).isSameOrBefore(moment(d).endOf('days'))
                && moment(i.end).isSameOrAfter(moment(d).startOf('days'))
            ) : null;
            if (currentWeekInMonth === currentWeek && d.isSameOrAfter(calendar?.validFrom)) {
              // check day of week
              if (/*d.week() === moment(d).startOf('months').add(weekIndex, 'weeks').week()
                  && d.isSameOrAfter(calendar?.validFrom)
                  &&*/ eventRule.dateRule.markedDays.indexOf(d.format('dddd').toLocaleUpperCase()) !== -1
              ) {
                if (!holiday) {
                  times.push({
                    start: d.set({
                      hour: eventRule.dateRule.start.split(':')[0],
                      minutes: eventRule.dateRule.start.split(':')[1]
                    }).format('YYYY-MM-DD HH:mm:ss'),
                    end: d.set({
                      hour: eventRule.dateRule.end.split(':')[0],
                      minutes: eventRule.dateRule.end.split(':')[1]
                    }).format('YYYY-MM-DD HH:mm:ss'),
                    isHoliday: eventRule.dateRule.holiday,
                  });
                }
              }
              // check holidays
              if (eventRule.dateRule.holiday && holiday) {
                times.push({
                  start: d.set({
                    hour: eventRule.dateRule.start.split(':')[0],
                    minutes: eventRule.dateRule.start.split(':')[1]
                  }).format('YYYY-MM-DD HH:mm:ss'),
                  end: d.set({
                    hour: eventRule.dateRule.end.split(':')[0],
                    minutes: eventRule.dateRule.end.split(':')[1]
                  }).format('YYYY-MM-DD HH:mm:ss'),
                  isHoliday: eventRule.dateRule.holiday,
                  holiday,
                });
              }
            }

            if (d.weekday() === 6) {
              currentWeekInMonth++;
              if (currentWeekInMonth >= countWeeks) {
                currentWeekInMonth = 0;
              }
            }
          }
          if (times.length) {
            let event = events.find(i => i.alarmplanId === eventRule.alarmplanId) ?? null;
            if (event) {
              event.times = event.times.concat(times);
            } else {
              event = {
                title: alarmPlan?.name || 'Postpone action',
                alarmplanId: eventRule.alarmplanId,
                times,
              };
              events.push(event);
            }
          }
        })
        currentWeek++;
        if (currentWeek > countWeeks) {
          currentWeek = 0;
        }
      });

      this.events = events;
      if (this.firstRun) {
        // This is a fix, do not delete this block
        this.firstRun = false;
        this.date = moment();
      }
      this.validateWeeks();
      return events;
    },
    validateWeeks: function () {
      this.invalidEvents = [];
      return JSON.parse(JSON.stringify(this.getEvents)).map((eventGroup, eventGroupIndex) => {
        if (!this.invalidEvents[eventGroupIndex]) {
          this.invalidEvents[eventGroupIndex] = new Set();
        }
        const eventGroups = eventGroup.alarmPointRotationEvents
        const checkCross = (a, b) => moment(a.start, 'HH:mm').isSameOrBefore(moment(b.end, 'HH:mm'))
            && moment(a.end, 'HH:mm').isSameOrAfter(moment(b.start, 'HH:mm'));

        if (eventGroups.length > 1) {
          for (let i = 0; i < eventGroups.length; i++) {
            for (let j = 0; j < eventGroups.length; j++) {
              if (i !== j
                  && checkCross(eventGroups[i].dateRule, eventGroups[j].dateRule)
                  && (
                      (eventGroups[i].dateRule.markedDays.includes(WeekDayEnum.MONDAY) && eventGroups[j].dateRule.markedDays.includes(WeekDayEnum.MONDAY))
                      || (eventGroups[i].dateRule.markedDays.includes(WeekDayEnum.TUESDAY) && eventGroups[j].dateRule.markedDays.includes(WeekDayEnum.TUESDAY))
                      || (eventGroups[i].dateRule.markedDays.includes(WeekDayEnum.WEDNESDAY) && eventGroups[j].dateRule.markedDays.includes(WeekDayEnum.WEDNESDAY))
                      || (eventGroups[i].dateRule.markedDays.includes(WeekDayEnum.THURSDAY) && eventGroups[j].dateRule.markedDays.includes(WeekDayEnum.THURSDAY))
                      || (eventGroups[i].dateRule.markedDays.includes(WeekDayEnum.FRIDAY) && eventGroups[j].dateRule.markedDays.includes(WeekDayEnum.FRIDAY))
                      || (eventGroups[i].dateRule.markedDays.includes(WeekDayEnum.SATURDAY) && eventGroups[j].dateRule.markedDays.includes(WeekDayEnum.SATURDAY))
                      || (eventGroups[i].dateRule.markedDays.includes(WeekDayEnum.SUNDAY) && eventGroups[j].dateRule.markedDays.includes(WeekDayEnum.SUNDAY))
                      || (eventGroups[i].dateRule.holiday && eventGroups[i].dateRule.holiday === eventGroups[j].dateRule.holiday)
                  )
              ) {
                this.invalidEvents[eventGroupIndex].add(i);
                this.invalidEvents[eventGroupIndex].add(j);
              }
            }
          }
        }
        return eventGroups;
      });
    },
    forceRerender() {
      this.renderCalendar = false;
      this.$nextTick(() => {
        this.renderCalendar = true;
      });
    },
    createUpdateNewCalendar() {
      const count = this.invalidEvents.reduce((acc, curr) => acc + curr.size, 0);
      delete this.getCalendar.membership
      if (this.getCalendar.name.length === 0 || !this.getCalendar.name) {
        this.validation.name = true
        return;
      } else {
        this.validation.name = false
      }
      if (count) {
        this.$confirm.require({
          message: this._t('label_invalid_lines_continue'),
          header: this._t('label_Confirmation'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            if (this.getCalendar.id) {
              this.callUpdateCalendar();
            } else {
              this.callCreateCalendar();
            }
            this.setIsEditable(false);
          },
          reject: () => {
            // nothing to do
          }
        });
      } else {
        if (this.getCalendar.id) {
          this.callUpdateCalendar();
        } else {
          this.callCreateCalendar();
        }
        this.setIsEditable(false);
      }
    },
  },
  computed: {
    ...mapGetters({
      'getCalendars': 'rotationalCalendar/getCalendars',
      'getCalendar': 'rotationalCalendar/getCalendar',
      'getEvents': 'rotationalCalendar/getEvents',
      'getAlarmPlans': 'rotationalCalendar/getAlarmPlans',
      'getIsEditable': 'rotationalCalendar/getIsEditable',
      'getHolidays': 'holidays/getHolidays',
    }),
    validFrom: {
      get() {
        let calendar = this.getCalendar;
        if (calendar) {
          return moment(calendar.validFrom, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
        }
        return null;
      },
      set(val) {
        let calendar = this.getCalendar;
        calendar.validFrom = moment(val).format('YYYY-MM-DD HH:mm:ss');
        this.setCalendar(calendar);
      }
    },
    weekDay: () => WeekDayEnum,
  },
  watch: {
    'calendarOptions.dow': function () {
      this.moment.updateLocale('en', {
        week: {
          dow: 1,
        }
      });
      this.forceRerender();
    },
    getEvents: function () {
      this.generateEvents();
    },
    date: function () {
      if (this.getCalendar) {
        this.generateEvents();
        this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()})
        this.moment.updateLocale('en', {
          week: {
            dow: 1,
          }
        });
      }
    },
    getAlarmPlans(value) {
      this.modifiedAlarmPlans = [...[{'name': this._t('postpone_action'), 'id': 0}], ...value];
    }
  },
}
</script>

<style>

</style>
